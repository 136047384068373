<template>
  <v-container
    id="accounts"
    fluid
    tag="section"
  >
    <div class="text-h3 font-weight-bold">
      Gerenciar tablets da loja
    </div>
    <v-row no-gutters class="mt-3 mx-3">
      <v-col cols="12" md="12" class="form-user-border blue-grey lighten-5">
        <ValidationObserver
          ref="formTablets"
          class="w-full"
          v-slot="{ pristine }"
          key="formTablets">
          <form key="formTablets">
            <v-row>
              <v-col cols="12" md="6" class="pt-2 mb-n4">
                <div class="text-h4 font-weight-bold ml-3">
                  {{ selectedUser && selectedUser.id && selectedUser.id > 0 ? 'Revisar usuário' : 'Novo usuário' }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-divider class="mx-0"
                  inset
                ></v-divider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <div class="ml-4">
                Informe os dados de acesso para o tablet
              </div>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="5" class="pl-5">
                <ValidationProvider
                  name="name"
                  vid="name"
                >
                  <v-text-field
                    v-model="selectedUser.name"
                    label="Nome"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="5" class="pl-4 pr-3">
                <ValidationProvider
                  name="sellerCode"
                  vid="sellerCode"
                >
                  <v-select
                    color="secondary"
                    item-color="secondary"
                    label="Código do vendedor"
                    :items="sellersList"
                    v-model="selectedSeller"
                    item-text="name"
                    return-object
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="2" class="px-4">
                <v-btn outlined color="warning" @click="cancelUser" block>
                  <i class="fas fa-ban font-size-18 mr-3"></i>
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="5" class="pl-5">
                <ValidationProvider
                  name="login"
                  vid="login"
                >
                  <v-text-field
                    v-model="selectedUser.email"
                    label="Login"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="5" class="pl-4 pr-3">
                <ValidationProvider
                  name="password"
                  vid="password"
                >
                  <v-text-field
                    v-model="selectedUser.password"
                    label="Senha"
                    append-outer-icon="fas fa-sync-alt"
                    @click:append-outer="generateNewPassword"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="2" class="px-4">
                <v-btn color="success" @click="saveUser" block>
                  <i class="fas fa-save font-size-18 mr-3"></i>
                  Salvar
                </v-btn>
              </v-col>
            </v-row>

          </form>
        </ValidationObserver>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headersUser"
          :items="usersList"
          :items-per-page="10"
          :search="filterText"
          class="elevation-1 mt-4"
          item-key="id"
          :loading="loadingUsers"
          loading-text="Carregando usuários..."
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-divider
                class="mx-4"
                inset
                vertical
              <v-spacer></v-spacer>
              <v-text-field
                class="mt-8"
                label="Filtrar"
                v-model="filterText"
                solo
                append-icon="mdi-magnify"
                style="max-width: 300px"
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item.modified="{ item }">
            {{ getFormattedDate(item.modified) }}
          </template>
          <template v-slot:item.last_login="{ item }">
            {{ getFormattedDateTime(item.last_login) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="font-size-12"
              color="primary"
              fab
              x-small
              @click="editUser(item)"
            >
              <i class="fas fa-pencil-alt"></i>
            </v-btn>
            <v-btn
              class="ml-3 font-size-12"
              color="error"
              fab
              x-small
              @click="deleteUser(item)"
            >
              <i class="far fa-trash-alt"></i>
            </v-btn>
            <v-btn v-if="hasAdminPermission"
              class="ml-3 font-size-12"
              color="primary"
              fab
              x-small
              @click="getTempPass(item)"
            >
              <i class="fas fa-key"></i>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="12" md="2">
        <v-btn @click.native="previousTab" block>
          Anterior
        </v-btn>
      </v-col>
      <v-col cols="12" md="2" offset="8">
        <v-btn @click.native="saveAndNextTab" block class="wizard-footer-right finish-button" color="primary">
            Próximo
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { UserService } from '@/code/services/user/userService';
import { User } from '@/code/models/User';
import moment from 'moment';
import { roles } from '@/code/util/constants';
import { EUserRole } from '@/code/enums/EUserRole';
import { EventBus } from '@/code/util/eventBus';
import { Confirm, Loading } from '@/code/util/swalUtil';
import Swal from 'sweetalert2';
import md5 from 'md5';
import { AuthUtil } from '@/code/util/authUtil';

export default {

  data() {
    return {
      idAccount: 0,
      usersList: [],
      selectedUser: {},
      sellersList: [],
      selectedSeller: {},
      filterText: "",
      loadingUsers: false,
      hasAdminPermission: Boolean,
      headersUser: [
        {
          text: 'Id',
          value: 'id',
          filterable: false
        },
        {
          text: 'Login',
          value: 'email',
          filterable: false
        },
        {
          text: 'Atualizado em',
          value: 'modified',
        }, {
          text: 'Último acesso em',
          value: 'last_login',
        },
        { text: 'Ações', value: 'actions', sortable: false }
      ],
    }
  },

  mounted() {
    this.selectedUser = this.createEmptyUser();
    this.idAccount = this.$route.params.id;
    if (this.idAccount && this.idAccount > 0) {
      this.loadUsers();
    } else {
      Swal.fire({
        title: 'Parâmetro inválido',
        text: 'A loja informada é invalida!',
        icon: 'error'
      })
      .then(
        (result) => {
          that.$router.replace({ name: 'assistentAction' });
        }
      )
    }

    const authUtil = new AuthUtil();
    const rule = authUtil.getUserRole();
    this.hasAdminPermission = rule === EUserRole.Admin;
  },

  methods: {
    loadUsers: async function() {
      this.loadingUsers = true;
      const service = new UserService();
      this.usersList = await service.getAllByAccount(this.idAccount);
      this.loadingUsers = false;
    },
    editUser: function(user) {
      this.showFormUser = true;
      this.selectedUser = new User(user.id, user);
    },
    createEmptyUser: function() {
      return {
        id: 0,
        name: '',
        seller_code: null,
        email: '',
        password: this.createPassword(),
      };
    },
    cancelUser: async function() {
      const dirty = await this.$refs.formTablets.flags.dirty;
      if (dirty) {
        Confirm.fire({
          title: "Há dados não salvos",
          text: "Os dados não salvos serão perdidos. Deseja continuar?"
        })
        .then(result => {
          if (result && result.isConfirmed) {
            this.selectedUser = this.createEmptyUser();
            this.selectedUser.password = this.createPassword();
          }
        })
      } else {
        this.selectedUser = this.createEmptyUser();
        this.selectedUser.password = this.createPassword();
      }
    },
    saveUser: async function() {
      Loading.fire("Salvando tablet!");
      const seller = roles.filter((r) => { return r.role_enum === EUserRole.Seller})[0];
      let toSave = {
        name: this.selectedUser.name,
        seller_code: this.selectedSeller && this.selectedSeller.id && this.selectedSeller.id > 0 ? this.selectedSeller.id : null,
        id_role: seller.id,
        email: this.selectedUser.email,
        password: this.selectedUser.password,

        detail: {
          country: 'BR'
        }
      }
      const userService = new UserService();
      if (this.selectedUser.id > 0) {
        if (await userService.update(this.selectedUser.id, toSave)) {
          const that = this;
          let users = this.usersList.filter((u) => u.id === that.selectedUser.id);
          if (users && users.length > 0) {
            users[0].email = this.selectedUser.email;
          } else {
            this.loadUsers();
          }
          this.selectedUser = this.createEmptyUser();
          Swal.close();
        }
      } else {
        toSave.id_accounts = this.idAccount;
        if (await userService.save(toSave)) {
          this.loadUsers();
          this.selectedUser = this.createEmptyUser();
          Swal.close();
        }
      }
    },
    deleteUser: async function(item) {
      if (item && item.id > 0) {
        const that = this;
        Confirm.fire(`Deseja realmente excluir o tablet ${item.login}`)
        .then(async (result) => {
          if (result && result.isConfirmed) {
            const userService = new UserService();
            const result = await userService.delete(item.id);
            if (result.isConfirmed) {
              const toRemove = that.usersList.filter((u) => u.id === item.id);
              if (toRemove && toRemove.length > 0) {
                that.usersList.splice(that.usersList.indexOf(toRemove[0]));
              }
            }
          }
        });
      }
    },
    getTempPass: function(item) {
      const user = item.login;
      const date = new Date();
      const key = `${item.login}-${date.getUTCDate()}-visio`;
      const hash = md5(key);
      console.log({ user, date: date.getUTCDate(), key, hash });
      Swal.fire({
        title: "Senha mestre",
        html: `<h3>Senha valida para a data de hoje</h3><br /><b>Login: </b> ${ item.login } <br /><b>Senha: </b> ${ hash.substr(0, 6) }`,
        icon: 'success'
      })
    },
    createPassword: function() {
      const length = 6;
      const charset = "abcdefghjkmnpqrstuvwxyz123456789";
      let retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },
    generateNewPassword: function() {
      this.selectedUser.password = this.createPassword();
    },
    getFormattedDate: function(date) {
      return date ? moment.utc(date).subtract(3,'hours').format('DD/MM/YYYY') : '';
    },
    getFormattedDateTime: function(date) {
      return date ? moment.utc(date).subtract(3,'hours').format('DD/MM/YYYY HH:mm') : '';
    },
    slugify: function(text)
    {
      return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
    },
    previousTab: async function() {
      const dirty = await this.$refs.formTablets.flags.dirty;
      if (dirty) {
        Confirm.fire('Os dados informados serão perdidos. Deseja voltar à etapa anterior?')
        .then(result => {
          if (result && result.isConfirmed) {
            EventBus.$emit('previousAssistentRoute');
          }
        });
      } else {
        EventBus.$emit('previousAssistentRoute');
      }
    },
    saveAndNextTab: async function() {
      const dirty = await this.$refs.formTablets.flags.dirty;
      if (dirty) {
        Confirm.fire('Os dados informados serão perdidos. Deseja avançar à próxima etapa?')
        .then(result => {
          if (result && result.isConfirmed) {
            EventBus.$emit('nextAssistentRoute');
          }
        });
      } else {
        EventBus.$emit('nextAssistentRoute');
      }
    }
  },

  computed: {
    userName() {
      return this.selectedUser ? this.selectedUser.name : '';
    },
  },

  watch: {
    userName(val) {
      if (!this.selectedUser) {
        return;
      }

      if (!this.selectedUser.id || this.selectedUser.id === 0) {
        this.selectedUser.email = this.slugify(val);
      }
    }
  },

}
</script>
